// TODO: refactor to export consts separately
export default {
  // tokenLifetime: 36e5, // 60 min in milliseconds
  // refreshTokenPeriod: 9e5, // 15 min in milliseconds
  // temporaryTokenLifeTime: 12e4, // 2 min
  urls: {
    user: process.env.USER_URL,
    cms: process.env.CMS_URL,
    farm: process.env.FARM_URL,
    email: process.env.EMAIL_URL,
    filer: process.env.FILER_URL,
    blog: process.env.BLOG_URL,
    investment: process.env.INVESTMENT_URL,
    notificationWS: process.env.NOTIFICATION_WS_URL,
    notification: process.env.NOTIFICATION_URL,
    frontend: process.env.FRONTEND_URL,
    plaid: process.env.PLAID_URL,
    hubspotForm: 'https://api.hsforms.com/submissions/v3/integration/submit',
    rssLink: process.env.RSS_LEARN_LINK,
    googleOptimizeHomeExperimentId: process.env.GOOGLE_OPTIMIZE_HOME_EXPERIMENT_ID,
    googleOptimizeOwnersExperimentId: process.env.GOOGLE_OPTIMIZE_FARM_OWNERS_ID,
    ip2location: process.env.IP2LOCATION_URL,
    altoAuth: `${process.env.ALTO_AUTH_URL}${window.location.origin}/alto/callback.html`,
    altoInvestment: process.env.ALTO_INVESTMENT_URL,
  },
  // staticDataPath: '/static',
  seo: {
    defaultTitle: 'Investing in Farmland Simplified',
    defaultDesc: 'AcreTrader is the farmland real estate investment company offering low minimum, passive farm investments. Invest in minutes and we will handle all of the details from farm management to paperwork and payments. Create your free account to start diversifying your portfolio today.',
    defaultImage: 'https://acretrader.com/static/img/sharing-image.png',
  },
  // maps: {
  //   apiKey: process.env.MAP_API_KEY,
  //   baseUrl: process.env.MAP_BASE_URL,
  // },
  // fileBaseUrl: process.env.MEDIA_URL,
  // fireBaseConfig: {
  //   apiKey: process.env.FIREBASE_APIKEY,
  //   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  //   databaseURL: process.env.FIREBASE_DATABASE_URL,
  //   projectId: process.env.FIREBASE_PROJECT_ID,
  //   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  // },
  auth0Config: {
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/callback.html`,
    responseType: 'id_token',
    scope: 'openid profile email',
    callbackURL: `${window.location.origin}/callback.html`,
    sso: true,
  },
  // auth0DB: process.env.AUTH0_DB_NAME,
  auth0Namespace: process.env.AUTH0_NAMESPACE,
  // disqus: {
  //   name: process.env.DISQUS_NAME,
  //   public_key: process.env.DISQUS_PUBLIC_KEY,
  // },
  hubspotPortalId: process.env.HUBSPOT_PORTAL_ID,
  hubspotFormIds: {
    subscribeNewsletter: process.env.HUBSPOT_FORM_ID_NEWSLETTER,
  //   downloadWhitepaper: process.env.HUBSPOT_FORM_ID_WHITEPAPER,
  //   farmOwner: process.env.HUBSPOT_FORM_ID_FARM_OWNER,
  //   registerUser: process.env.HUBSPOT_FORM_ID_REGISTER_USER,
  //   getStarted: process.env.HUBSPOT_FORM_ID_GET_STARTED,
  //   downloadAdditionalDocuments: process.env.HUBSPOT_FORM_ID_ADDITIONAL_DOCUMENTS,
  //   contactUs: process.env.HUBSPOT_FORM_ID_CONTACT_US,
  //   comingSoon: process.env.HUBSPOT_FORM_ID_COMING_SOON,
  //   signUpComingSoon: process.env.HUBSPOT_FORM_ID_SIGN_UP_COMING_SOON,
  //   investmentExperience: process.env.HUBSPOT_FORM_ID_INVESTMENT_EXPERIENCE,
  //   farmPreview: process.env.HUBSPOT_FORM_ID_FARM_PREVIEW,
  //   subscribeNewsletterLearning: process.env.HUBSPOT_FORM_ID_LEARNING_SUBSCRIBE,
  //   stayInTheKnow: process.env.HUBSPOT_FORM_STAY_IN_THE_KNOW,
  },
  // plaid: {
  //   env: process.env.PLAID_ENV,
  //   publicKey: process.env.PLAID_PUBLIC_KEY,
  // },
  // dwollaTransactionLimit: process.env.DWOLLA_TRANSACTION_LIMIT,
  // northCapitalTransactionLimit: process.env.NORTH_CAPITAL_TRANSACTION_LIMIT,
  siftAccount: process.env.SIFT_ACCOUNT,
  dmcaId: process.env.DMCA_ID,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  // mediaGCloudUrl: process.env.MEDIA_G_CLOUD_URL,
  // addEventClientId: process.env.ADDEVENT_CLIENT_ID,
  isActiveMaintenanceMode: process.env.IS_ACTIVE_MAINTENANCE_MODE === 'true',
  maintenanceScheduledDate: process.env.MAINTENANCE_DATE,
  pageScrollOffsetTop: 80,
  isMarketEnabled: process.env.IS_MARKET_ENABLED === 'true',
};
