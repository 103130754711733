import { computed, reactive } from 'vue';
import { clearStorageLoginData, getLoginData, ILoginData } from '@/services/auth';

function getInitialLoginData(): ILoginData {
  return {
    token: null,
    isEmailVerified: false,
  };
}

const loginData: ILoginData = reactive(getInitialLoginData());

export function useLogin() {
  function clearLoginData(): void {
    clearStorageLoginData();
    Object.assign(loginData, getInitialLoginData());
  }

  function setLoginData(): void {
    Object.assign(loginData, getLoginData());
  }

  async function logout() {
    clearLoginData();
    const { default: auth } = await import(/* webpackChunkName: "auth" */ '@/services/auth');
    await auth.logout();
  }

  const isLoggedIn = computed(() => Boolean(loginData.token));
  const isEmailVerified = computed(() => !isLoggedIn.value || loginData.isEmailVerified);

  return {
    token: loginData.token,
    isLoggedIn,
    isEmailVerified,
    setLoginData,
    logout,
  };
}
