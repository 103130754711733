import Auth0 from 'auth0-js';
import CONFIG from 'Config';

const webAuth = new Auth0.WebAuth({
  ...CONFIG.auth0Config,
});

export default class AuthAuth0 {
  webAuth: Auth0.WebAuth

  constructor() {
    this.webAuth = webAuth;
  }

  logout(): Promise<boolean> {
    return new Promise(((resolve) => {
      const iframe = document.createElement('iframe');
      iframe.src = webAuth.client.buildLogoutUrl({
        returnTo: CONFIG.auth0Config.redirectUri,
        clientID: CONFIG.auth0Config.clientID,
      });
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.addEventListener('load', () => {
        setTimeout(() => document.body.removeChild(iframe), 2000);
        resolve(true);
      });
    }));
  }
}
