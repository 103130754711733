import { ref, shallowReadonly, Ref } from 'vue';
import { getUserProfile } from '@/services/api/user';
import { IUserProfileData } from 'Models';

const userProfile: Ref<IUserProfileData> = ref({
  first_name: '', // eslint-disable-line camelcase
  last_name: '', // eslint-disable-line camelcase
});

export function useUserProfile() {
  async function getProfileData(): Promise<IUserProfileData> {
    const data = await getUserProfile();
    userProfile.value = data;
    return data;
  }

  return {
    getProfileData,
    profileData: shallowReadonly(userProfile),
  };
}
