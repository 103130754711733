import { computed } from 'vue';
import { PROFILE_TYPES } from '@/../const/investment.json';
import { useInvestmentProfileList } from './investmentProfileList';

const { investmentProfileList } = useInvestmentProfileList();

const investmentProfileIndividual = computed(() => (
  investmentProfileList.value.find(item => item.type === PROFILE_TYPES.INDIVIDUAL) || {}
));

export function useInvestmentProfileIndividual() {
  return {
    investmentProfileIndividual,
  };
}
