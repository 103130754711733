export class RequestError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any

  response: Response

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(response: Response, data?: any) {
    super(response.statusText);
    this.response = response;
    this.data = data;
  }
}
