import { ref, readonly } from 'vue';
import { ILearningRandomPostList } from 'Models';
import CONFIG from 'Config';

const learningRandomPostList = ref([]);

export function useLearningRandomPostList(): ILearningRandomPostList {
  async function getLearningRandomPostList() {
    const category = 'acretrader-101';
    const limit = '3';
    const data = await fetch(`${CONFIG.urls.cms}/category/${category}?order=random&limit=${limit}`)
      .then(response => response.json());
    learningRandomPostList.value = data.data;
    return data;
  }

  return {
    learningRandomPostList: readonly(learningRandomPostList),
    getLearningRandomPostList,
  };
}
