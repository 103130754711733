import { RouteLocation } from 'vue-router';
import CONFIG from 'Config';
import {
  LINK_CLICKED,
  BEGIN_INVESTING,
  GET_STARTED,
  EXPLAINER_WATCH_END,
  EXPLAINER_WATCH_START,
  ADVISOR_REQUEST_INTRODUCTION,
} from './events';

export function triggerEvent(event: string, data = {}) {
  window.analytics.track(event, { ...data });
}

export function triggerPage(event: string) {
  window.analytics.page(event);
}

export function triggerExplainerWatchStart(): void {
  triggerEvent(EXPLAINER_WATCH_START);
}

export function triggerExplainerWatchEnd(data: { [key: string]: string|number|boolean }): void {
  triggerEvent(EXPLAINER_WATCH_END, data);
}

export function triggerGetStarted(email: string): void {
  triggerEvent(GET_STARTED, { email });
}

export function triggerBeginInvesting(): void {
  triggerEvent(BEGIN_INVESTING);
}

export function triggerLinkClicked(data = {}): void {
  triggerEvent(LINK_CLICKED, data);
}

export function triggerAdvisorButtonClick(): void {
  triggerEvent(ADVISOR_REQUEST_INTRODUCTION);
}

function triggerSiftPageView(): void {
  const _sift = window._sift = window._sift || []; // eslint-disable-line
  _sift.push(['_setAccount', CONFIG.siftAccount]);
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _sift.push(['_setUserId', email]);
  // }
  _sift.push(['_trackPageview']);
}

function triggerHSPageView(route: RouteLocation): void {
  const _hsq = window._hsq = window._hsq || []; // eslint-disable-line
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _hsq.push(['identify', { email }]);
  // } else {
  _hsq.push(['identify']);
  // }
  _hsq.push(['setPath', route.fullPath]);
  _hsq.push(['trackPageView']);
}

export function onPageResolved(route: RouteLocation): void {
  setTimeout(() => {
    triggerPage(route.path);
    triggerSiftPageView();
    triggerHSPageView(route);
  }, 1500);
}
