import { createApp, createSSRApp } from 'vue';
import { useLogin } from 'Composable';
import { useMaintenance } from '@/composables/maintenanceMode';
import AppComponent from './App.vue';
import router from './router';

const { setLoginData } = useLogin();
setLoginData();

const { checkHashForSuperUser } = useMaintenance();
checkHashForSuperUser();

async function createAndMount() {
  if (window.__hydration) {
    const app = createSSRApp(AppComponent).use(router);
    await router.isReady();
    app.mount('#app');
  } else {
    const app = createApp(AppComponent).use(router);
    await router.isReady();
    app.mount('#app');
  }
}

createAndMount();
