import { ref, readonly } from 'vue';
import CONFIG from 'Config';
import { getUrlParamsByObject } from 'Utils/url';
import { IComposableSoldFarmList } from 'Models';

const soldFarmList = ref([]);

export function useSoldFarmList(): IComposableSoldFarmList {
  async function getSoldFarmList(limit = 10000) {
    const data = await fetch(`${CONFIG.urls.farm}/exited${getUrlParamsByObject({ limit, order: 'sold-exited' })}`)
      .then(response => response.json());
    soldFarmList.value = data.data;
    return data;
  }

  return {
    soldFarmList: readonly(soldFarmList),
    getSoldFarmList,
  };
}
