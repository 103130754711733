<template>
  <BaseSpinner
    :show="isShowSpinner"
    opaque
    full
  />
  <router-view />
</template>

<script>
import { triggerLinkClicked } from '@/analytics';
import { defineComponent, computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useScreenSize, useLogin } from 'Composable';
import BaseSpinner from 'Components/Base/BaseSpinner.vue';

export default defineComponent({
  name: 'App',
  components: {
    BaseSpinner,
  },
  setup() {
    const isMounted = ref(false);
    const { setScreenWidth } = useScreenSize();
    const { isLoggedIn } = useLogin();

    const isShowSpinner = computed(() => !isMounted.value && !window.__prerender);

    function setViewDimensions() {
      setScreenWidth(document.documentElement.clientWidth);
    }

    function clickEventListener(e) {
      if (e.target.href || e.target.baseURI) {
        triggerLinkClicked({ href: e.target.href, baseURI: e.target.baseURI, text: e.target.outerText });
      }
    }

    function clearStaticPreload() {
      const spinner = document.getElementById('preload-spinner');
      if (spinner) spinner.style.display = 'none';
    }

    onMounted(async () => {
      clearStaticPreload();
      setViewDimensions();
      window.addEventListener('resize', setViewDimensions);
      window.addEventListener('click', clickEventListener);
      await nextTick();
      isMounted.value = true;
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setViewDimensions);
      window.removeEventListener('click', clickEventListener);
    });

    async function getLoggedInUserData() {
      const { useGeneralOnLoggedInData } = await import(/* webpackChunkName: "generalOnLoggedInData" */
        '@/composables/generalOnLoggedInData');
      const { getGeneralOnLoggedInData } = useGeneralOnLoggedInData();
      getGeneralOnLoggedInData();
    }

    async function setupWSSubscribes() {
      const { useGeneralWSSubscribes } = await import(/* webpackChunkName: "generalWSSubscribes" */
        '@/composables/generalWSSubscribes');
      const { setupSubscribes } = useGeneralWSSubscribes();
      setupSubscribes();
    }

    async function setupWS() {
      const { authWSClient } = await import(/* webpackChunkName: "wsClient" */ '@/services/wsClient');
      authWSClient.open();
    }

    async function setupMarketMessages() {
      const { useMarketMessagesHandler } = await import(/* webpackChunkName: "useMarketMessagesHandler" */
        '@/composables/useMarketMessagesHandler');
      useMarketMessagesHandler();
    }

    if (isLoggedIn.value) {
      setupWS();
      setupWSSubscribes();
      getLoggedInUserData();
      setupMarketMessages();
    }

    return {
      isShowSpinner,
    };
  },
});
</script>

<style lang="sass">
@import 'styles/app.scss'
</style>
