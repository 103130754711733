import { getTokenFromStorage } from '@/services/auth/simpleAuth';
import { useLogin } from 'Composable/login/login';
import { RequestError } from './RequestError';

type RequestDataType = Record<string, unknown>|FormData;

function _getBody(data: RequestDataType) {
  if (!data) return undefined;
  if (data instanceof FormData) return data;
  return JSON.stringify(data);
}

function _getOptions(method: string, data?: RequestDataType): RequestInit {
  const headers: Record<string, string> = { 'Content-Type': 'application/json;charset=UTF-8' };
  const token = getTokenFromStorage();
  if (token) headers.Authorization = token;
  return {
    method,
    body: data ? _getBody(data) : undefined,
    headers,
  };
}

function _parseJSON<ResponseDataType>(response: Response): Promise<ResponseDataType> {
  return response.text()
    .then((text) => {
      let result = {};
      try {
        result = JSON.parse(text);
      } catch (ignore) { } // eslint-disable-line
      return result as ResponseDataType;
    });
}

function _checkStatus(response: Response, data: unknown): void {
  if (response.ok) return;
  if ([401, 403].includes(response.status)) {
    const { isLoggedIn, logout } = useLogin();
    if (isLoggedIn.value) logout();
  }
  throw new RequestError(response, data);
}

export async function request<ResponseDataType>(url: string, method = 'GET', data?: RequestDataType): Promise<ResponseDataType> {
  return fetch(url, _getOptions(method, data))
    .then(async (response) => {
      const parsedData = await _parseJSON<ResponseDataType>(response);
      _checkStatus(response, parsedData);
      return parsedData;
    });
}
