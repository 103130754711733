export enum INVESTMENT_PROFILE_KYC_STATUS {
  DEACTIVATED = 'deactivated',
  DOCUMENT = 'document',
  REACTIVATED = 'reactivated',
  RETRY = 'retry',
  SUSPENDED = 'suspended',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
  DOCUMENT_SENT = 'document_sent',
  RETRY_SENT = 'retry_sent'
}
