
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseSpinner',
  props: {
    show: Boolean,
    opaque: Boolean,
    full: Boolean,
  },
});
