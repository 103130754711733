import CONFIG from 'Config';
import { IInvestmentProfile, IInvestmentProfileAccreditationIssue } from 'Models';
import { request } from '../request';
import { IListResponseData } from '../types';

export function fetchInvestmentProfileList(): Promise<IListResponseData<IInvestmentProfile>> {
  return request<IListResponseData<IInvestmentProfile>>(`${CONFIG.urls.investment}/profile`);
}

export function fetchInvestmentProfileAccreditationIssueList(): Promise<IInvestmentProfileAccreditationIssue[]> {
  return request<IInvestmentProfileAccreditationIssue[]>(`${CONFIG.urls.investment}/accreditation-issues`);
}
