// TODO: rename to farmListClosed
import { ref, readonly } from 'vue';
import CONFIG from 'Config';
import { getUrlParamsByObject } from 'Utils/url';
import { IComposableClosedFarmList } from 'Models';

const closedFarmList = ref([]);

export function useClosedFarmList(): IComposableClosedFarmList {
  async function getClosedFarmList(limit = 10000, s?: string) {
    const data = await fetch(`${CONFIG.urls.farm}/closed${getUrlParamsByObject({ limit, s })}`)
      .then(response => response.json());
    closedFarmList.value = data.data;
    return data;
  }

  return {
    closedFarmList: readonly(closedFarmList),
    getClosedFarmList,
  };
}
