import { computed } from 'vue';
import { IDeviceSize } from 'Models';
import CONFIG from 'Config';
import { useScreenSize } from './screenSize';

const { screenWidth } = useScreenSize();

const isBigTablet = computed((): boolean => (screenWidth.value < CONFIG.breakpoints.lg));
const isTablet = computed((): boolean => (screenWidth.value < CONFIG.breakpoints.md));
const isMobile = computed((): boolean => (screenWidth.value <= CONFIG.breakpoints.sm));

export function useDeviceSize(): IDeviceSize {
  return {
    isBigTablet,
    isTablet,
    isMobile,
  };
}
