import { ref, shallowReadonly, Ref } from 'vue';
import { IInvestmentProfile } from 'Models';
import { fetchInvestmentProfileList } from '@/services/api/investment';

const investmentProfileList: Ref<IInvestmentProfile[]> = ref([]);

export function useInvestmentProfileList() {
  async function getInvestmentProfileList() {
    const investmentProfileListResponse = await fetchInvestmentProfileList();
    investmentProfileList.value = investmentProfileListResponse.data;
    return investmentProfileListResponse;
  }

  return {
    investmentProfileList: shallowReadonly(investmentProfileList),
    getInvestmentProfileList,
  };
}
