// TODO: rename to farmListClosed
import { ref, readonly } from 'vue';
import CONFIG from 'Config';
import { IStats } from 'Models';

const farmStats = ref({} as IStats);

export function useFarmStats() {
  async function getStats() {
    const data = await fetch(`${CONFIG.urls.farm}/stats`)
      .then(response => response.json());
    farmStats.value = data;
    return data;
  }

  return {
    farmStats: readonly(farmStats),
    getStats,
  };
}
