import { ILoginData } from './LoginDataInterface';

const TOKEN_KEY = 'token';
const IS_EMAIL_VERIFIED_KEY = 'isEmailVerified';

export function getTokenFromStorage(): null | string {
  return window.localStorage.getItem(TOKEN_KEY) || null;
}

export function clearStorageLoginData(): void {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(IS_EMAIL_VERIFIED_KEY);
}

export function getLoginData(): ILoginData {
  const token = getTokenFromStorage();
  const isEmailVerified = window.localStorage.getItem(IS_EMAIL_VERIFIED_KEY) === 'true';
  return {
    token,
    isEmailVerified,
  };
}
