import { computed } from 'vue';
import { PROFILE_TYPES } from '@/../const/investment.json';
import { INVESTMENT_PROFILE_KYC_STATUS } from 'Config/investment-profile-kyc-status';
import { INVESTMENT_PROFILE_KYC_CERTIFICATION_STATUS } from 'Config/investment-profile-kyc-certification-status';
import { useInvestmentProfileList } from './investmentProfileList';

const { investmentProfileList } = useInvestmentProfileList();

const isSomeInvestmentProfileKYCReady = computed(() => (
  investmentProfileList.value.some(profile => (
    profile.status === INVESTMENT_PROFILE_KYC_STATUS.VERIFIED
    && (
      profile.type !== PROFILE_TYPES.ENTITY
      || profile.certification_status === INVESTMENT_PROFILE_KYC_CERTIFICATION_STATUS.CERTIFIED
    )
  ))
));

export function useInvestmentProfileKYCStatus() {
  return {
    isSomeInvestmentProfileKYCReady,
  };
}
